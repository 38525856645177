input[type=text], input[type=password], input[type=email], input[type=tel], input[type=link], input[type=number], textarea, input, select {
    box-shadow: none;
    padding: calc(var(--init) * 2) calc(var(--init) * 3);
    width: -webkit-fill-available;
    border-radius: calc(var(--init) * 1.25);
    font-family: var(--fw-400);
    font-size: calc(var(--init) * 2);
}

input[type=text]:focus, input[type=number]:focus, input[type=email]:focus, input[type=tel]:focus, select:focus, input[type=link]:focus, input:focus, textarea:focus, input:focus[type=password] {
    outline: none;
    box-shadow: none;
}

input[type="file"]::-webkit-file-upload-button {
    display: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-overflow: ellipsis;
    white-space: nowrap;
}

select::-ms-expand {
    display: none;
}