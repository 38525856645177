/* ============================== */

/* ============================== */

/* ========== Font Style ========== */

/* ============================== */

/* ============================== */

.fw-100 {
    font-family: var(--fw-100) !important;
}

.fw-100-i {
    font-family: var(--fw-100-i) !important;
}

.fw-200 {
    font-family: var(--fw-200) !important;
}

.fw-200-i {
    font-family: var(--fw-200-i) !important;
}

.fw-300 {
    font-family: var(--fw-300) !important;
}

.fw-300-i {
    font-family: var(--fw-300-i) !important;
}

.fw-400 {
    font-family: var(--fw-400) !important;
}

.fw-400-i {
    font-family: var(--fw-400-i) !important;
}

.fw-500 {
    font-family: var(--fw-500) !important;
}

.fw-500-i {
    font-family: var(--fw-500-i) !important;
}

.fw-600 {
    font-family: var(--fw-600) !important;
}

.fw-600-i {
    font-family: var(--fw-600-i) !important;
}

.fw-700 {
    font-family: var(--fw-700) !important;
}

.fw-700-i {
    font-family: var(--fw-700-i) !important;
}

.fw-800 {
    font-family: var(--fw-800) !important;
}

.fw-800-i {
    font-family: var(--fw-800-i) !important;
}

/* ============================== */

/* ============================== */

/* ========== Font Size ========== */

/* ============================== */

/* ============================== */

.display-1 {
    font-size: calc(var(--init)*9) !important;
}

.display-2 {
    font-size: calc(var(--init)*8) !important;
}

.display-3 {
    font-size: calc(var(--init)*7.5) !important;
}

.display-4 {
    font-size: calc(var(--init)*7) !important;
}

.display-5 {
    font-size: calc(var(--init)*6.5) !important;
}

.display-6 {
    font-size: calc(var(--init)*6) !important;
}

h1, .h1 {
    position: relative;
    font-size: calc(var(--init)*5) !important;
}

h2, .h2 {
    position: relative;
    font-size: calc(var(--init)*4) !important;
}

h3, .h3 {
    position: relative;
    font-size: calc(var(--init)*3.5) !important;
}

h4, .h4 {
    position: relative;
    font-size: calc(var(--init)*3) !important;
}

h5, .h5 {
    position: relative;
    font-size: calc(var(--init)*2.5) !important;
}

h6, .h6 {
    position: relative;
    font-size: calc(var(--init)*2) !important;
}

.h7 {
    position: relative;
    font-size: calc(var(--init)*1.5) !important;
}

/* ============================== */

/* ============================== */

/* ========== Font Color Solid ========== */

/* ============================== */

/* ============================== */

.text-theme {
    color: var(--theme) !important;
}

.text-danger {
    color: var(--danger) !important;
}

.text-dangerDark {
    color: var(--danger-dark) !important;
}

.text-primary {
    color: var(--primary) !important;
}

.text-primaryDark {
    color: var(--primary-dark) !important;
}

.text-success {
    color: var(--success) !important;
}

.text-successDark {
    color: var(--success-dark) !important;
}

.text-warning {
    color: var(--warning) !important;
}

.text-warningDark {
    color: var(--warning-dark) !important;
}

.text-ascent1 {
    color: var(--ascent1) !important;
}

.text-ascent1Dark {
    color: var(--ascent1-dark) !important;
}

.text-ascent2 {
    color: var(--ascent2) !important;
}

.text-ascent2Dark {
    color: var(--ascent2-dark) !important;
}

.text-default {
    color: var(--text) !important;
}

.text-black {
    color: var(--black) !important;
}

.text-white {
    color: var(--white) !important;
}

.text-whiteMutedDark {
    color: var(--whiteMutedDark) !important;
}

.text-muted{
    color: var(--blackMutedDark) !important;
}

/* ============================== */

/* ============================== */

/* ========== Font Color Gradient ========== */

/* ============================== */

/* ============================== */

.text-gradient-theme, .text-gradient-primary, .text-gradient-danger, .text-gradient-success, .text-gradient-warning, .text-gradient-ascent1, .text-gradient-ascent2 {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-theme {
    background-image: var(--theme-radial-gradient) !important;
}

.text-gradient-primary {
    background-image: var(--primary-radial-gradient) !important;
}

.text-gradient-danger {
    background-image: var(--danger-radial-gradient) !important;
}

.text-gradient-success {
    background-image: var(--success-radial-gradient) !important;
}

.text-gradient-warning {
    background-image: var(--warning-radial-gradient) !important;
}

.text-gradient-ascent1 {
    background-image: var(--ascent1-radial-gradient) !important;
}

.text-gradient-ascent2 {
    background-image: var(--ascent2-radial-gradient) !important;
}

/*  */

.text-decoration-none{
    text-decoration: none !important;
}

.font-weight-bold{
    font-weight: bold;
}

.text-transform-capitalize{
    text-transform: capitalize;
}

.text-transform-initial{
    text-transform: initial;
}

.text-transform-lowercase{
    text-transform: lowercase;
}

.text-transform-uppercase{
    text-transform: uppercase;
}

.text-initial{
    color: inherit;
}