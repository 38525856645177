/* Font Weight 100 */

@font-face {
    font-family: "centra-hairline";
    src: url("./assets/fonts/CentraNo2-Hairline.woff") format("woff");
}

@font-face {
    font-family: "centra-hairline-italic";
    src: url("./assets/fonts/CentraNo2-HairlineItalic.woff") format("woff");
}

/* Font Weight 200 */

@font-face {
    font-family: "centra-thin";
    src: url("./assets/fonts/CentraNo2-Thin.woff") format("woff");
}

@font-face {
    font-family: "centra-thin-italic";
    src: url("./assets/fonts/CentraNo2-ThinItalic.woff") format("woff");
}

/* Font Weight 300 */

@font-face {
    font-family: "centra-light";
    src: url("./assets/fonts/CentraNo2-Light.woff") format("woff");
}

@font-face {
    font-family: "centra-light-italic";
    src: url("./assets/fonts/CentraNo2-LightItalic.woff") format("woff");
}

/* Font Weight 400 */
/* Normal Font */

@font-face {
    font-family: "centra-book";
    src: url("./assets/fonts/CentraNo2-Book.woff") format("woff");
}

@font-face {
    font-family: "centra-book-italic";
    src: url("./assets/fonts/CentraNo2-BookItalic.woff") format("woff");
}

/* Font Weight 500 */

@font-face {
    font-family: "centra-medium";
    src: url("./assets/fonts/CentraNo2-Medium.woff") format("woff");
}

@font-face {
    font-family: "centra-medium-italic";
    src: url("./assets/fonts/CentraNo2-MediumItalic.woff") format("woff");
}

/* Font Weight 600 */

@font-face {
    font-family: "centra-bold";
    src: url("./assets/fonts/CentraNo2-Bold.woff") format("woff");
}

@font-face {
    font-family: "centra-bold-italic";
    src: url("./assets/fonts/CentraNo2-BoldItalic.woff") format("woff");
}

/* Font Weight 700 */

@font-face {
    font-family: "centra-extrabold";
    src: url("./assets/fonts/CentraNo2-Extrabold.woff") format("woff");
}

@font-face {
    font-family: "centra-extrabold-italic";
    src: url("./assets/fonts/CentraNo2-ExtraboldItalic.woff") format("woff");
}

/* Font Weight 800 */

@font-face {
    font-family: "centra-black";
    src: url("./assets/fonts/CentraNo2-Black.woff") format("woff");
}

@font-face {
    font-family: "centra-black-italic";
    src: url("./assets/fonts/CentraNo2-BlackItalic.woff") format("woff");
}