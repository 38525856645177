.carousel {
    margin-top: var(--navHeight);
}

.carousel .carouselItem{
    height: 80vh;
    width: 100%;
}

.carousel .carouselItem img.carouselItemThumbnail {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.carousel .carouselItem .carouselItemDataPanel{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(30deg, #000000cc, transparent);
}


.carousel .carouselItem .carouselItemDataPanel button.status{
    backdrop-filter: blur(20px);
    background: #00000044;
    color: white;
}

.carousel-prev, .carousel-next{
    background: #ffffff !important;
    padding: 0.5rem !important;
    height: auto !important;
    width: auto !important;
    transform: translateY(39vh) !important;
    margin: 1rem !important;
}

.carousel-prev:before, .carousel-next:before{
    -webkit-filter: grayscale(100%) brightness(2);
    filter: grayscale(100%) brightness(2);
}