.messageWindow {
    height: 100vh;
    width: 30vw;
    position: fixed;
    right: -35vw;
    top: 0;
    background-color: var(--themeMuted);
    transition: right 0.3s;
}

.messageWindow.active {
    right: 0 !important;
    transition: right 0.3s;
}

.messages {
    box-shadow: 0 -5px 10px #3f3f3fAA inset, 0 5px 10px #3f3f3fAA inset;
    margin-left: calc(var(--init) * -2) !important;
    margin-right: calc(var(--init) * -2) !important;
}

.tab1.active {
    border-bottom: 2px #ffffff solid;
}


.tab1.active {
    border-bottom: 2px #ffffff solid;
}

.activeBar {
    width: 50%;
    height: 5px;
    background-color: #ffffff;
    margin-left: 0;
    transition: margin 0.2s;
}

/*.activeBar.tab1{*/
/*    margin-left:0;*/
/*    transition: margin-left 0.3s;*/
/*}*/

.activeBar.tab2 {
    margin-left: 50%;
    transition: margin 0.2s;
}

.badge {
    height: 0.7rem;
    width: 0.7rem;
    border-radius: 100%;
}