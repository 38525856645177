.watchParty {
    margin-top: calc(var(--navHeight) + 1rem);
}

.watchParty .watchPartyUser {
    height: 90vh;
    overflow-y: auto;
    position: relative;
}

.watchParty .watchPartySearch {
    height: 90vh;
}

.watchParty .watchPartyUser .title {
    position: sticky;
    top: 0;
    z-index: 1;
}

.watchParty .watchPartyUserItem {
    position: relative;
    overflow: visible;
}

.watchParty .watchPartyUserItem img {
    height: calc(var(--init) * 6);
    width: calc(var(--init) * 6);
    object-fit: cover;
}


.watchParty .watchPartyUserItem button.removeBtn {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(20%, -20%);
}

@media (max-width: 525px) {
    .watchParty .watchPartyUserItem button.removeBtn {
        transform: translate(-50%, 50%);
    }
    .watchParty .watchPartyUser{
        height: fit-content;
        overflow-y: none;
    }
    .watchParty .watchPartySearch {
        height: auto;
    }
}
