header#mainHeader {
    height: var(--navHeight);
    background: #202020bb;
    border-bottom: 1px solid transparent;
    transition: var(--transition);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

header#mainHeader.sticky{
    transition: var(--transition);
    background: var(--themeMuted);
    border-bottom: 1px solid var(--themeMutedDark);
}

header.main .logo {
    height: calc(var(--navHeight) * 0.9);
}

header#mainHeader ul.webView{
    display: flex;
    align-items: center;
    height: 100%;
}

header#mainHeader ul.webView li {
    border-bottom: 3px solid transparent;
    color: var(--whiteMutedDark);
}

header#mainHeader ul.webView li:hover,
header#mainHeader ul.webView li.active {
    border-bottom: 3px solid var(--white);
    color: var(--white);
}

header#mainHeader ul.mobileView{
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    width: min(60%, 240px);
    background: var(--themeMuted);
    height: 100%;
    list-style: none;
    transition: var(--transition);
    padding: 1rem;
    box-shadow: -1rem 0 1rem 0 #00000000;
}

header#mainHeader ul.mobileView.active{
    right: 0;
    transition: var(--transition);
    box-shadow: -1rem 0 1rem 0 #00000044;
}

header#mainHeader ul.mobileView li{
    border-bottom: 1px solid var(--whiteMutedDark);
    padding: 1rem;
    color: var(--whiteMutedDark);
}

header#mainHeader ul.mobileView li:first-child{
    margin-top: 1rem;
}

header#mainHeader ul.mobileView li:hover,
header#mainHeader ul.mobileView li.active {
    border-bottom: 1px solid var(--white);
    color: var(--white);
    background: var(--theme);
}

header#mainHeader ul.mobileView .sidebarHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
