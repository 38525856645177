.w-25{
    width: 25% !important;
}

.w-50{
    width: 50% !important;
}

.w-75{
    width: 75% !important;
}

.w-100{
    width: 100% !important;
}

.h-100{
    height: 100% !important;
}

.w-1_3{
    width: calc(100%/3);
}

.w-48{
    width: 48% !important;
}

.w-fill{
    width: -webkit-fill-available;
}

.h-fill{
    height: -webkit-fill-available;
}
.h-75{
    height: 75% !important;
}