.nextPremiere {
    margin-top: var(--navHeight);
    position: relative;
}

.nextPremiere .nextPremiereThumbnail {
    height: 80vh;
    width: 100%;
}

.nextPremiere .nextPremiereThumbnail img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.nextPremiere .nextPremiereDataPanel {
    height: 100%;
    width: 100%;
    background: linear-gradient(30deg, #000000cc, transparent);
    position: absolute;
    top: 0;
    left: 0;
}

.nextPremiere .nextPremiereDataPanel button.notifyButton {
    position: relative;
}

.nextPremiere .nextPremiereDataPanel button.notifyButton:hover svg {
    animation: bellIcon 0.2s infinite alternate-reverse;
}

.nextPremiere .nextPremiereDataPanel button.notifyButton.active {
    background: var(--white);
    color: var(--themeColor2);
    transition: none;
}

.nextPremiere .nextPremiereDataPanel button.notifyButton span {
    display: none;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: calc(100% + 0.5rem);
    width: max-content;
    background: var(--theme);
    color: var(--white);
    padding: calc(var(--init) * 1);
}

.nextPremiere .nextPremiereDataPanel button.notifyButton:hover span {
    display: block;
}

@keyframes bellIcon {
    from {
        transform: rotateZ(15deg);
    }
    to {
        transform: rotateZ(-15deg);
    }
}

@media (max-width: 720px){
    .nextPremiere .nextPremiereThumbnail{
        height: fit-content;
    }
    .nextPremiere .nextPremiereDataPanel{
        position: relative;
    }
}
