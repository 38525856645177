.d-none {
    display: none !important;
}

.d-flex {
    display: flex;
}

.d-center {
    display: grid;
    place-items: center;
    align-content: center;
}

.d-flex-column {
    display: flex;
    flex-direction: column !important;
}

.align-items-initial {
    align-items: initial !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-evenly {
    justify-content: space-evenly;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-space {
    justify-content: space-around;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-start {
    justify-content: flex-start;
}

.flex-wrap{
    flex-wrap: wrap;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.flex-6 {
    flex: 6;
}

.flex-7 {
    flex: 7;
}

.flex-8 {
    flex: 8;
}

.flex-9 {
    flex: 9;
}

.flex-10 {
    flex: 10;
}

.flex-11 {
    flex: 11;
}

.flex-12 {
    flex: 12;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-justify {
    text-align: justify !important;
}

.align-self-start{
    align-self: start;
}

.align-self-end{
    align-self: end;
}

.justify-self-start{
    justify-self: start;
}
