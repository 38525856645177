.searchPage{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #202026ee;
    z-index: 9999;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 100px 0px #000000;
}

.searchPage .searchPageCloseButton{
    position: absolute;
    right: 40px;
    top: 40px;
}

.searchPage .searchForm{
    width: min(600px, 80%);
}