header.profile{
    margin-top:var(--navHeight);
    height: var(--navHeight);
    background: #202020;
    border-bottom: 1px solid transparent;
    transition: var(--transition);
}
header.profile ul li{
    border-bottom: 3px solid transparent;
    color: var(--whiteMutedDark);
}

header.profile ul li.active{
    border-bottom: 3px solid var(--white);
    color: var(--white);
}

@media (max-width: 545px) {
    header.profile{
        height: max-content;
    }
}
