.position-relative{
    position: relative;
}

.position-sticky{
    position: sticky;
}

.top{
    top: 0;
}

.right{
    right: 0;
}

.left{
    left: 0;
}

.bottom{
    bottom: 0;
}