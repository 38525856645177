footer {
    background: var(--themeMuted);
    padding: 5rem 0rem;
    border-top: 1px solid var(--themeMutedDark);
}

footer input {
    font-size: 0.8rem;
    background: var(--theme);
}

footer p {
    font-size: small;
}

footer button {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    color: var(--white);
    background: var(--primary);
}

footer .footer-data {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-row-gap: 2rem;
    grid-column-gap: 2rem;
}

footer .footer-data .block-1 {
    grid-row: 1/2;
    grid-column: 1/4;
}

footer .footer-data .block-2 {
    grid-row: 1/2;
    grid-column: 4/7;
}

footer .footer-data .block-3 {
    grid-row: 1/2;
    grid-column: 7/10;
}

footer .footer-data .block-4 {
    grid-row: 1/2;
    grid-column: 10/13;
}

footer .footer-data .footer_title {
    font-family: var(--fw-600);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

footer .footer-data ul {
    list-style: none;
}

footer .footer-data ul li {
    margin-bottom: 0.4rem;
}

footer .footer-data ul li a {
    color: var(--whiteMutedDark);
    text-decoration: none;
}

footer .footer-data ul li a:hover {
    color: inherit;
}

footer.oneView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0%;
    min-height: var(--navHeight);
    font-size: 80%;
    font-family: var(--fw-400);
}

.socialMedia ul {
    display: flex;
    list-style: none;
    margin-top: 1rem;
}

.socialMedia ul li {
    font-size: 1.2rem;
    margin-right: 1rem;
}

.socialMedia ul li:last-child {
    margin-right: 0rem;
}

.socialMedia ul li a .fab {
    color: var(--whiteMutedDark);
    transition: var(--transition);
}

.socialMedia ul li:nth-child(1) a:hover .fab {
    color: #3B5998;
    transition: var(--transition);
}

.socialMedia ul li:nth-child(2) a:hover .fab {
    color: #00ACED;
    transition: var(--transition);
}

.socialMedia ul li:nth-child(3) a:hover .fab {
    color: #DD4B39;
    transition: var(--transition);
}

.socialMedia ul li:nth-child(4) a:hover .fab {
    color: #007BB6;
    transition: var(--transition);
}

.socialMedia ul li:nth-child(5) a:hover .fab {
    color: #E4405F;
    transition: var(--transition);
}

@media (max-width: 1216px) {
    footer .footer-data .block-1 {
        grid-row: 1/2;
        grid-column: 1/13;
    }

    footer .footer-data .block-2 {
        grid-row: 2/3;
        grid-column: 1/7;
    }

    footer .footer-data .block-3 {
        grid-row: 2/3;
        grid-column: 7/13;
    }

    footer .footer-data .block-4 {
        grid-row: 3/4;
        grid-column: 1/13;
    }
}

@media (max-width: 396px) {
    footer .footer-data {
        grid-template-columns: 1fr;
    }

    footer .footer-data .block-1 {
        grid-row: 1/2;
        grid-column: 1/2;
    }

    footer .footer-data .block-2 {
        grid-row: 2/3;
        grid-column: 1/2;
    }

    footer .footer-data .block-3 {
        grid-row: 3/4;
        grid-column: 1/2;
    }

    footer .footer-data .block-4 {
        grid-row: 4/5;
        grid-column: 1/2;
    }
}

.footer {
    background: var(--themeMuted);
    padding: 1rem 0rem;
    border-top: 1px solid var(--themeMutedDark);
    justify-content: center;
    align-items: center;
    display: flex;
}

.footer a::after {
    content: "|";
    margin: 0 1rem;
}

.footer a:last-child::after {
    content: "";
}

.links {
    text-decoration: none !important;
    color: var(--white)
}

@media (max-width: 480px) {
    .footer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 1rem;
        padding: 5%;
    }

    .footer a::after {
        content: none;
    }
}

.footer-fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
}