.videoItemList {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1rem;
    background: var(--themeMuted);
}

.videoItemList .videoItemList-img {
    grid-column: 1/4;
}

.videoItemList .videoItemList-data {
    grid-column: 4/12;
}

.videoItemList .videoItemList-action {
    grid-column: 12/13;
    justify-self: self-end;
}

.videoItemList .videoItemList-img img {
    aspect-ratio: 16/9;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .videoItemList {
        grid-template-rows: auto;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 1rem;
        position: relative;
    }

    .videoItemList .videoItemList-img {
        grid-row: 1/2;
        grid-column: 1/7;
    }

    .videoItemList .videoItemList-data {
        grid-row: 2/3;
        grid-column: 1/7;
    }

    .videoItemList .videoItemList-action {
        position: absolute;
        top: 24px;
        right: 24px;
    }


}