header.season{
    position: sticky;
    top: var(--navHeight);
    left: 0;
    right: 0;
    z-index: 2;
    background-color:var(--theme);
}
header.season ul li{
    border-bottom: 3px solid transparent;
    color: var(--whiteMutedDark);
}

header.season ul li.active{
    border-bottom: 3px solid var(--white);
    color: var(--white);
}

.series-data {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1rem;
    position: relative;
}

.series-data .series-seasons {
    grid-column: 1/13;
}

.series-data .series-seasons .series-episodes {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1rem;
}

.series-data .series-seasons .series-episodes .episode-thumbnail {
    grid-column: 1/2;
}

.series-data .series-seasons .series-episodes .episode-details {
    grid-column: 2/7;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.series-data .series-seasons .series-episodes .episode-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 16/9
}

@media (max-width: 768px) {
    .series-data .series-seasons .series-episodes .episode-thumbnail {
        grid-column: 1/3;
    }

    .series-data .series-seasons .series-episodes .episode-details {
        grid-column: 3/7;
        font-size: small;
    }
}

@media (max-width: 425px) {
    .series-data .series-seasons .series-episodes .episode-thumbnail {
        grid-column: 1/7;
    }

    .series-data .series-seasons .series-episodes .episode-details {
        grid-column: 1/7;
        font-size: small;
    }
}

.header-margin{
    margin-top: calc(var(--navHeight) * 1.5);
}