.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #20202688;
    height: 100%;
    width: 100%;
    z-index: 9999;
    display: grid;
    place-items: center;
}

.modal::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(10px);
}

.modal-content {
    width: min(500px, 80%);
    max-width: 80%;
    min-height: 200px;
    max-height: 80%;
    box-shadow: 0 10px 20px 0 #00000050;
    background: var(--themeMuted);
    z-index: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border-radius: calc(var(--init) * 2);
}

.modal-content-2 {
    max-width: 80%;
    min-height: 200px;
    max-height: 80%;
    box-shadow: 0 10px 20px 0 #00000050;
    background: var(--themeMuted);
    z-index: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border-radius: calc(var(--init) * 2);
}

@media (max-width: 768px) {
    .modal-content-2 {
        max-width: 100%;
        max-height: 100%;
    }
}

.modal .modal-content .modal-title {
    background: var(--themeMuted);
    position: sticky;
    top: 0;
    z-index: 1;
}

.modal .modal-content .modal-body {
    padding: calc(var(--init) * 2);
    flex: 1;
    overflow-y: auto;
}

.modal .modal-content-2 .modal-title {
    background: var(--themeMuted);
    position: sticky;
    top: 0;
    z-index: 1;
}

.modal .modal-content-2 .modal-body {
    padding: calc(var(--init) * 2);
    flex: 1;
    overflow-y: auto;
}

.modal .modal-content-2 .modal-title {
    background: var(--themeMuted);
    position: sticky;
    top: 0;
    z-index: 1;
}

.modal .modal-content-2 .modal-body {
    padding: calc(var(--init)*2);
    flex: 1;
    overflow-y: auto;
}