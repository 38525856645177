.br0{
    border-radius: 0;
}
.br1 {
    border-radius: calc(var(--init) * 1);
    overflow: hidden;
}

.br2 {
    border-radius: calc(var(--init) * 2);
    overflow: hidden;
}

.br3 {
    border-radius: calc(var(--init) * 3);
    overflow: hidden;
}

.br4 {
    border-radius: calc(var(--init) * 4);
    overflow: hidden;
}

.circle {
    border-radius: 50%;
}

.border-right {
    border-right: 1px solid var(--theme);
}

.border-right-Muted {
    border-right: 1px solid var(--themeMuted);
}
.border-right-MutedDark {
    border-right: 1px solid var(--themeMutedDark);
}
.border-bottom {
    border-bottom: 1px solid var(--themeMutedDark);
} 
.border {
    border: 1px solid var(--themeMuted);
}
.border2{
    border: 2px solid var(--themeMuted);
}