.myWatchParty {
    display: grid;
    grid-gap: calc(var(--init) * 2);
    grid-template-columns: repeat(2, 1fr);
}

.myWatchParty .block{
    display: flex;
    flex-direction: column;
    height: 70vh;
}

.myWatchParty .block .blockBody{
    flex: 1;
    overflow-y: auto;
}

@media (max-width: 991px) {
    .myWatchParty {
        display: grid;
        grid-gap: calc(var(--init) * 2);
        grid-template-columns: 1fr;
    }
    .myWatchParty .block{
        height: max-content;
    }
    .myWatchParty .block .blockBody{
        height: fit-content;
        overflow-y: initial;
    }
}

.watchParty-loading{
    display: flex;
    flex-direction: column;
    height: 70vh;
}