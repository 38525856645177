@import './assets/css/fonts.css';
@import './assets/css/typography.css';
@import './assets/css/spacing.css';
@import './assets/css/button.css';
@import './assets/css/align.css';
@import './assets/css/border.css';
@import './assets/css/form.css';
@import './assets/css/sizing.css';
@import "./assets/css/position.css";
@import './fonts.css';

:root {
    /* font-family */
    --fw-100: centra-hairline;
    --fw-100-i: centra-hairline-italic;
    --fw-200: centra-thin;
    --fw-200-i: centra-thin-italic;
    --fw-300: centra-light;
    --fw-300-i: centra-light-italic;
    --fw-400: centra-book;
    --fw-400-i: centra-book-italic;
    --fw-500: centra-medium;
    --fw-500-i: centra-medium-italic;
    --fw-600: centra-bold;
    --fw-600-i: centra-bold-italic;
    --fw-700: centra-extrabold;
    --fw-700-i: centra-extrabold-italic;
    --fw-800: centra-black;
    --fw-800-i: centra-black-italic;
    /* Theme */
    --theme: #000000;
    --themeMuted: #202020;
    --themeMutedDark: #404040;
    --themeColor1: #b615de;
    --themeColor2: #d028a3;
    /* Shades of White */
    --white: #ffffff;
    --whiteMuted: #dfdfdf;
    --whiteMutedDark: #bfbfbf;
    /* Shades of Black*/
    --black: #000000;
    --blackMuted: #3f3f3f;
    --blackMutedDark: #6f6f6f;
    /* Default Colors */
    --primary: #1aa3ff;
    --primaryDark: #008ae6;
    --success: #42d395;
    --successDark: #29b97b;
    --danger: #f64a6c;
    --dangerDark: #dd3153;
    --warning: #fcc166;
    --warningDark: #fbb140;
    --ascent1: #29d2f1;
    --ascent1Dark: #0fb9d7;
    --ascent2: #6e80e7;
    --ascent2Dark: #5567cd;
    /* Essentials */
    --navHeight: 60px;
    --init: 0.5rem;
    --transition: all 0.4s ease;
}

*, *:before, *:after {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

*:focus-visible {
    outline: none !important;
}

input, input:before, input:after {
    -webkit-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
}

html, body {
    margin: 0;
    padding: 0;
    font-size: initial;
    font-family: var(--fw-400);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricPrecision;
    scroll-behavior: smooth !important;
    background: var(--theme);
    color: var(--white);
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
}

body::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #88888888;
    border-radius: 10rem;
    -moz-border-radius: 10rem;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none !important;
}

.container {
    width: min(1440px, 90vw);
    margin: auto;
}

button {
    background: var(--white);
}

.line1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-clamp: 1;
    -webkit-line-clamp: 1;
}

.line2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.line3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.line4 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}

.letter-space {
    letter-spacing: 1px;
}

.stayConnectedTheme {
    background: linear-gradient(to right, var(--themeColor1), var(--themeColor2));
}

.auth {
    background: linear-gradient(to right, #000000bb, #000000bb), url("./assets/img/auth/bg.png") center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
}

.auth > .authProcess {
    backdrop-filter: blur(10px);
    width: 1140px;
    min-height: 520px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 2fr;
}

.auth > .authProcess > .authStaticLogo {
    grid-column: 1/2;
    grid-row: 1/2;
}

.auth > .authProcess > .authStaticData {
    grid-column: 1/2;
    grid-row: 2/3;
}

.auth > .authProcess > .authProcessData {
    grid-column: 2/3;
    grid-row: 1/3;
}

.auth > .authProcess > .authStaticLogo img {
    height: calc(var(--init) * 15);
    width: auto;
}

.auth > .authProcess > .authStaticData ul {
    list-style: none;
}

.auth > .authProcess > .authStaticData ul li::before {
    content: "✓";
    margin-right: calc(var(--init) * 1);
}

.auth > .authProcess > .authStaticData ul li:not(:last-child) {
    margin-bottom: calc(var(--init) * 1);
}

.auth > .authProcess > .authStaticData p.copyrightText{
    margin-top: 0;
}

.auth > .authProcess > .authProcessData .authProcessDataPanel {
    background: #20202060;
    height: 100%;
}

.successGif {
    width: 100%;
    height: calc(var(--init) * 20);
    object-fit: cover;
}

.text-themeStayConnected {
    color: var(--themeColor1);
}

.oneview {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.oneview2 {
    height: calc(100vh - var(--navHeight));
    width: 100vw;
    overflow: hidden;
}

.playerIcon {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, #000000, transparent);
    top: 0;
    left: 0;
}

@media (max-width: 425px) {
    .oneview {
        width: 100vh;
        height: 100vw;
    }
}

.square {
    width: 2.4em;
    height: 2.4em;
}

.square-small {
    width: 1.8em;
    height: 1.8em;
}

.square-lg {
    width: 3em;
    height: 3em;
}

.small {
    font-size: 90% !important;
}

.x-small {
    font-size: 75% !important;
}

.list-style-none {
    list-style: none;
}

.cursor {
    cursor: initial !important;
}

.cursor:hover {
    cursor: pointer !important;
}

.hover-underline {
    text-decoration: initial;
}

.hover-underline:hover {
    text-decoration: underline !important;
}

.transparent {
    background: none !important;
}

.first-letter-capital {
    text-transform: inherit;
}

.first-letter-capital:first-letter {
    text-transform: capitalize
}

.overflowY {
    overflow-y: auto;
}

.overflowY::-webkit-scrollbar {
    display: none;
}

.overflowX {
    overflow-x: scroll;
}

.bg-theme {
    background: var(--theme);
}

.bg-themeMuted {
    background: var(--themeMuted);
}

.bg-themeMutedDark {
    background: var(--themeMutedDark);
}

.bg-transparentMuted {
    background: #40404088;
}

.bg-primary{
    background: var(--primary);
}

.bg-success{
    background: var(--success);
}

.bg-danger{
    background: var(--danger);
}

img, svg {
    display: block;
}

form label {
    width: 100%;
    font-family: var(--fw-500);
    margin-bottom: 0.2rem;
    display: inline-block;
    font-size: 1rem;
}

form input,
form textarea {
    width: 100%;
    max-width: 100%;
    font-family: var(--fw-400);
    padding: 0.8rem 1rem !important;
    font-size: 1rem;
    border: 2px solid var(--theme);
    background: var(--theme);
    transition: var(--transition);
    color: inherit;
}
::-ms-reveal {
    filter: invert(100%);
}
form textarea {
    min-width: 100%;
    max-width: 100%;
}


form input:active,
form input:focus,
form input:focus-visible,
form textarea:active,
form textarea:focus,
form textarea:focus-visible {
    outline: none;
    border-color: var(--themeMutedDark);
    background: var(--themeMuted);
    transition: var(--transition);
}

form .form_field {
    margin-bottom: 1rem;
}

form .form_field:last-child {
    margin-bottom: 0 !important;
}

.footer-script {
    background: var(--themeMuted);
    border-top: 1px solid var(--themeMutedDark);
    display:flex;
    justify-content:center;
    padding: 0.5rem 0;
}

.rapidscansecure img {
    height: calc(var(--init) * 5);
    margin-right: 1rem;
}

.rapidscansecure a {
    display: inline-flex;
    align-self: flex-start;
}

.anSeal img {
    height: calc(var(--init) * 5);
    left: 0;
    width: auto;
    padding-right: 0.4rem;
    padding-bottom: 0.4rem;
}

.anSeal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1140px) {
    .auth > .authProcess {
        width: 520px;
        min-height: fit-content;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: calc(Var(--init) *2);
    }

    .auth > .authProcess > .authStaticLogo {
        grid-column: 1/2;
        grid-row: 1/2;
    }

    .auth > .authProcess > .authStaticData {
        grid-column: 1/2;
        grid-row: 3/4;
    }

    .auth > .authProcess > .authProcessData {
        grid-column: 1/2;
        grid-row: 2/3;
    }
    .auth > .authProcess > .authStaticLogo img {
        height: calc(var(--init) * 10);
        width: auto;
    }
    .auth > .authProcess > .authStaticData p.copyrightText{
        margin-top: calc(Var(--init) * 2);
        text-align: center;
    }
}

@media (max-width: 991px){
    .auth > .authProcess {
        width: 520px;
    }
}

@media (max-width: 520px){
    .auth{
        height: fit-content;
    }
    .auth > .authProcess {
        width: 100%;
    }
}
