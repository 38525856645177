.player{
    height: calc( min(1440px, 90vw) * 9/16);
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
}

.playerFrame{
    height: 100%;
    width: 100%;
    aspect-ratio: 16/9;
}

.rotate-90{
    transform: rotate(90deg);
}