/* ============================== */

/* ============================== */

/* ========== Button Style ========== */

/* ============================== */

/* ============================== */

button {
    border: none;
    cursor: pointer;
    font-family: var(--fw-500);
    font-size: calc(var(--init)*2.25);
    border-radius: calc(var(--init)*1.25);
    transition: var(--transition);
    padding: calc(var(--init)*1.5) calc(var(--init)*3.5);
    -webkit-tap-highlight-color: transparent;
    width: fit-content;
}

@media (max-width: 1366px){
    button{
        font-size: calc(var(--init)*2);
        padding: calc(var(--init)*1) calc(var(--init)*2.5);
    }
}

button:disabled {
    background: var(--themeMuted);
    color: var(--whiteMutedDark);
    pointer-events: none;
}

button:hover {
    transition: var(--transition);
    filter: brightness(0.975);
}

button.theme {
    background: var(--theme);
    color: var(--white) !important;
}

button.primary {
    background: var(--primary);
    color: var(--white) !important;
}

button.danger {
    background: var(--danger);
    color: var(--white) !important;
}

button.success {
    background: var(--success);
    color: var(--white) !important;
}

button.successDark {
    background: var(--successDark);
    color: var(--white) !important;
}

button.warning {
    background: var(--warning);
    color: var(--white) !important;
}

button.warningDark {
    background: var(--warningDark);
    color: var(--white) !important;
}

button.ascent1 {
    background: var(--ascent1);
    color: var(--white) !important;
}

button.ascent2 {
    background: var(--ascent2);
    color: var(--white) !important;
}

/* ============================== */

/* ============================== */

/* ========== Button Size ========== */

/* ============================== */

/* ============================== */

button.small {
    font-size: calc(var(--init)*2);
    padding: calc(var(--init)*1) calc(var(--init)*2.5);
}

button.large {
    font-size: calc(var(--init)*2.5);
    padding: calc(var(--init)*2) calc(var(--init)*4.5);
}

button.circle {
    border-radius: calc(var(--init)*99);
}

button.square{
    padding: 0px;
    margin: 0px;
    width: 36px;
    height: 36px;
    display: grid;
    place-items: center;
}

button.pointer-none{
    pointer-events: none !important;
}

