.upcomingListed {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-template-rows: auto;
    grid-gap: calc(Var(--init) * 3);
    margin-top: var(--navHeight);
}

.upcomingListed .card {
    border: 1px solid var(--themeMuted);
    background: var(--theme);
    transition: background 0.4s ease;
    position: relative;
}

.upcomingListed .card button.notifyButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: var(--theme);
    color: var(--white);
}

.upcomingListed .card button.notifyButton.active,
.upcomingListed .card:hover button.notifyButton {
    background: var(--white);
    color: var(--themeColor2);
    transition: none;
}

.upcomingListed .card:hover button.notifyButton svg {
    animation: bellIcon 0.2s infinite alternate-reverse;
}

.upcomingListed .card button.notifyButton span {
    display: none;
    position: absolute;
    top: 0;
    right: calc(100% + 0.5rem);
    width: max-content;
    background: var(--theme);
    color: var(--white);
    padding: calc(var(--init) * 1);
}

.upcomingListed .card button.notifyButton:hover span {
    display: block;
}

@keyframes bellIcon {
    from {
        transform: rotateZ(15deg);
    }
    to {
        transform: rotateZ(-15deg);
    }
}

.upcomingListed .card:hover {
    background: var(--themeMuted);
    transition: background 0.4s ease;
}

.upcomingListed .card img.thumbnail {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;
}
