.videoBlock {
    position: relative;
    height: fit-content;
    width: fit-content;
    transform: scale(1);
    transition: var(--transition);
    box-shadow: 0px 0px 0px 0px var(--theme);
    margin-right: 2rem;
}

.videoBlock:first-child {
    margin: 0rem 2rem;
}

.videoBlock:hover {
    transform: scale(1);
    transition: var(--transition);
    box-shadow: 0px 0px 30px 5px var(--theme);
}

.videoBlock:first-child:hover {
    transform-origin: left;
    transition: var(--transition);
}

.videoBlock:last-child:hover {
    transform-origin: right;
    transition: var(--transition);
}

.videoBlock .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: transparent;
    transition: var(--transition);
}

.videoBlock:hover .overlay {
    transition: var(--transition);
    background: linear-gradient(to top, #000000dd 0%, transparent 50%);
}

.videoBlock img {
    height: calc(var(--init) * 30);
    aspect-ratio: 16/9;
    object-fit: cover;
}

.videoBlock .videoBlockData {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    display: none;
    transform: translateY(100%);
    width: 100%;
    transition: var(--transition);
}

.videoBlock:hover .videoBlockData {
    display: block;
    opacity: 1;
    transform: translateY(0%);
    transition: var(--transition);
}

.videoViewBar{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: red;
    height: 3px;
}

.homeContent{
    margin-top: -15rem;
    z-index: 1;
}

@media (max-width: 1440px){
    .homeContent{
        margin-top: -10rem;
    }
}

@media (max-width: 1080px){
    .homeContent{
        margin-top: -5rem;
    }
}

@media (max-width: 768px){
    .homeContent{
        margin-top: 0rem;
    }
}

@media (max-width: 768px) {
    .videoBlock {
        margin-right: 1rem;
    }

    .videoBlock:first-child {
        margin: 0rem 1rem;
    }

    .videoBlock img {
        height: calc(var(--init) * 20);
    }
}