/* ============================== */

/* ============================== */

/* ========== Margin ========== */

/* ============================== */

/* ============================== */

/* Margin auto Style */

.m-auto {
    margin: auto !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.mt-auto {
    margin-top: auto !important;
}

.me-auto {
    margin-right: auto !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ms-auto {
    margin-left: auto !important;
}

/* Margin 0 Style */

.m-0 {
    margin: calc(var(--init)*0) !important;
}

.my-0 {
    margin-top: calc(var(--init)*0) !important;
    margin-bottom: calc(var(--init)*0) !important;
}

.mx-0 {
    margin-right: calc(var(--init)*0) !important;
    margin-left: calc(var(--init)*0) !important;
}

.mt-0 {
    margin-top: calc(var(--init)*0) !important;
}

.me-0 {
    margin-right: calc(var(--init)*0) !important;
}

.mb-0 {
    margin-bottom: calc(var(--init)*0) !important;
}

.ms-0 {
    margin-left: calc(var(--init)*0) !important;
}

/* Margin 1 Style */

.m-1 {
    margin: calc(var(--init)*1) !important;
}

.my-1 {
    margin-top: calc(var(--init)*1) !important;
    margin-bottom: calc(var(--init)*1) !important;
}

.mx-1 {
    margin-right: calc(var(--init)*1) !important;
    margin-left: calc(var(--init)*1) !important;
}

.mt-1 {
    margin-top: calc(var(--init)*1) !important;
}

.me-1 {
    margin-right: calc(var(--init)*1) !important;
}

.mb-1 {
    margin-bottom: calc(var(--init)*1) !important;
}

.ms-1 {
    margin-left: calc(var(--init)*1) !important;
}

/* Margin 2 Style */

.m-2 {
    margin: calc(var(--init)*2) !important;
}

.my-2 {
    margin-top: calc(var(--init)*2) !important;
    margin-bottom: calc(var(--init)*2) !important;
}

.mx-2 {
    margin-right: calc(var(--init)*2) !important;
    margin-left: calc(var(--init)*2) !important;
}

.mt-2 {
    margin-top: calc(var(--init)*2) !important;
}

.me-2 {
    margin-right: calc(var(--init)*2) !important;
}

.mb-2 {
    margin-bottom: calc(var(--init)*2) !important;
}

.ms-2 {
    margin-left: calc(var(--init)*2) !important;
}

/* Margin 3 Style */

.m-3 {
    margin: calc(var(--init)*3) !important;
}

.my-3 {
    margin-top: calc(var(--init)*3) !important;
    margin-bottom: calc(var(--init)*3) !important;
}

.mx-3 {
    margin-right: calc(var(--init)*3) !important;
    margin-left: calc(var(--init)*3) !important;
}

.mt-3 {
    margin-top: calc(var(--init)*3) !important;
}

.me-3 {
    margin-right: calc(var(--init)*3) !important;
}

.mb-3 {
    margin-bottom: calc(var(--init)*3) !important;
}

.ms-3 {
    margin-left: calc(var(--init)*3) !important;
}

/* Margin 4 Style */

.m-4 {
    margin: calc(var(--init)*4) !important;
}

.my-4 {
    margin-top: calc(var(--init)*4) !important;
    margin-bottom: calc(var(--init)*4) !important;
}

.mx-4 {
    margin-right: calc(var(--init)*4) !important;
    margin-left: calc(var(--init)*4) !important;
}

.mt-4 {
    margin-top: calc(var(--init)*4) !important;
}

.me-4 {
    margin-right: calc(var(--init)*4) !important;
}

.mb-4 {
    margin-bottom: calc(var(--init)*4) !important;
}

.ms-4 {
    margin-left: calc(var(--init)*4) !important;
}

/* Margin 5 Style */

.m-5 {
    margin: calc(var(--init)*5) !important;
}

.my-5 {
    margin-top: calc(var(--init)*5) !important;
    margin-bottom: calc(var(--init)*5) !important;
}

.mx-5 {
    margin-right: calc(var(--init)*5) !important;
    margin-left: calc(var(--init)*5) !important;
}

.mt-5 {
    margin-top: calc(var(--init)*5) !important;
}

.me-5 {
    margin-right: calc(var(--init)*5) !important;
}

.mb-5 {
    margin-bottom: calc(var(--init)*5) !important;
}

.ms-5 {
    margin-left: calc(var(--init)*5) !important;
}


.ms-auto{
    margin-left: auto !important;
}

/* ============================== */

/* ============================== */

/* ========== Padding ========== */

/* ============================== */

/* ============================== */

/* Padding 0 Style */

.p-0 {
    padding: 0 !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pe-0 {
    padding-right: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.ps-0 {
    padding-left: 0 !important;
}

/* Padding 1 Style */

.p-1 {
    padding: calc(var(--init)*1) !important;
}

.py-1 {
    padding-top: calc(var(--init)*1) !important;
    padding-bottom: calc(var(--init)*1) !important;
}

.px-1 {
    padding-right: calc(var(--init)*1) !important;
    padding-left: calc(var(--init)*1) !important;
}

.pt-1 {
    padding-top: calc(var(--init)*1) !important;
}

.pe-1 {
    padding-right: calc(var(--init)*1) !important;
}

.pb-1 {
    padding-bottom: calc(var(--init)*1) !important;
}

.ps-1 {
    padding-left: calc(var(--init)*1) !important;
}

/* Padding 2 Style */

.p-2 {
    padding: calc(var(--init)*2) !important;
}

.py-2 {
    padding-top: calc(var(--init)*2) !important;
    padding-bottom: calc(var(--init)*2) !important;
}

.px-2 {
    padding-right: calc(var(--init)*2) !important;
    padding-left: calc(var(--init)*2) !important;
}

.pt-2 {
    padding-top: calc(var(--init)*2) !important;
}

.pe-2 {
    padding-right: calc(var(--init)*2) !important;
}

.pb-2 {
    padding-bottom: calc(var(--init)*2) !important;
}

.ps-2 {
    padding-left: calc(var(--init)*2) !important;
}

/* Padding 3 Style */

.p-3 {
    padding: calc(var(--init)*3) !important;
}

.py-3 {
    padding-top: calc(var(--init)*3) !important;
    padding-bottom: calc(var(--init)*3) !important;
}

.px-3 {
    padding-right: calc(var(--init)*3) !important;
    padding-left: calc(var(--init)*3) !important;
}

.pt-3 {
    padding-top: calc(var(--init)*3) !important;
}

.pe-3 {
    padding-right: calc(var(--init)*3) !important;
}

.pb-3 {
    padding-bottom: calc(var(--init)*3) !important;
}

.ps-3 {
    padding-left: calc(var(--init)*3) !important;
}

/* Padding 4 Style */

.p-4 {
    padding: calc(var(--init)*4) !important;
}

.py-4 {
    padding-top: calc(var(--init)*4) !important;
    padding-bottom: calc(var(--init)*4) !important;
}

.px-4 {
    padding-right: calc(var(--init)*4) !important;
    padding-left: calc(var(--init)*4) !important;
}

.pt-4 {
    padding-top: calc(var(--init)*4) !important;
}

.pe-4 {
    padding-right: calc(var(--init)*4) !important;
}

.pb-4 {
    padding-bottom: calc(var(--init)*4) !important;
}

.ps-4 {
    padding-left: calc(var(--init)*4) !important;
}

/* Padding 5 Style */

.p-5 {
    padding: calc(var(--init)*5) !important;
}

.py-5 {
    padding-top: calc(var(--init)*5) !important;
    padding-bottom: calc(var(--init)*5) !important;
}

.px-5 {
    padding-right: calc(var(--init)*5) !important;
    padding-left: calc(var(--init)*5) !important;
}

.pt-5 {
    padding-top: calc(var(--init)*5) !important;
}

.pe-5 {
    padding-right: calc(var(--init)*5) !important;
}

.pb-5 {
    padding-bottom: calc(var(--init)*5) !important;
}

.ps-5 {
    padding-left: calc(var(--init)*5) !important;
}