.horizontalContainer{
    position: relative;
}

.horizontalContainer .horizontalLeftAction{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    background: linear-gradient(to left, transparent, var(--theme));
    color: white;
    width: 100px;
    font-size: smaller;
    z-index: 99;
    border-radius: 0;
}

.horizontalContainer .horizontalRightAction{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(to right, transparent, var(--theme));
    color: white;
    width: 100px;
    font-size: smaller;
    z-index: 99;
    border-radius: 0;
}

.horizontalContainer .horizontalItems{
    display: flex;
    overflow: auto;
    white-space: nowrap;
}

@media (max-width: 425px) {
    .horizontalContainer .horizontalLeftAction,
    .horizontalContainer .horizontalRightAction{
        width: 40px;
        font-size: xx-small;
        padding: 0%;
    }
}